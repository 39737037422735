/// <reference types="@angular/localize" />

import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getLanguageFromUrl } from '../utils';
import { posthogAllowedUrls } from './app/core/services/analytics.service';

declare var Cookiebot;

if (environment.production) {
  enableProdMode();
}

setCookieBanner();

window.addEventListener(
  'CookiebotOnAccept',
  function (e) {
    if (Cookiebot.consent.marketing && environment.production) {
      setThirdPartyMarketingCookies();
    }
  },
  false
);

mixpanel.init(environment.mixpanelKey, { debug: false, track_pageview: false, persistence: 'localStorage', enable_persistence: false });

posthog.init(environment.posthogKey, {
  api_host: 'https://eu.posthog.com',
  capture_pageview: false,
  autocapture: false,
  debug: false,
  persistence: 'memory',
  disable_session_recording: true,
});

function setCookieBanner() {
  var hideCookieConsent = window.location.search.includes('cookie=false');
  if (!hideCookieConsent) {
    var domain = window.location.hostname + (window.location.port ? ':' + window.location.port : '');

    const cookieConsent = document.createElement('script');
    cookieConsent.type = 'text/javascript';
    cookieConsent.id = 'Cookiebot';

    cookieConsent.src = `https://consent.cookiebot.com/uc.js`;
    cookieConsent.setAttribute('data-cbid', '636cd409-b3c5-4bc4-aa6e-e11549accdbb');

    const { languageCode, url } = getLanguageFromUrl(domain, window.location.pathname);
    cookieConsent.setAttribute('data-culture', (languageCode || 'en').toUpperCase());

    document.head.appendChild(cookieConsent);
  }
}

function setThirdPartyMarketingCookies() {
  // mixpanel
  mixpanel.set_config({ enable_persistence: true });
  // posthog
  posthog.set_config({ persistence: 'localStorage' });
  // facebook pixel
  const fb = document.createElement('script');
  var url = window.location.href;
  var posthogRegexes = posthogAllowedUrls;
  var allowedUrlForMerchants = false;
  for (var i = 0; i < posthogRegexes.length; i++) {
    var regex = new RegExp(posthogRegexes[i]);
    if (regex.test(url)) {
      allowedUrlForMerchants = true;
      break;
    }
  }
  fb.innerHTML = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  if ('${allowedUrlForMerchants}' === 'true') {
    fbq('init', '${environment.facebookPixel}');
    fbq('trackSingle', '${environment.facebookPixel}', 'PageView');
  }
  `;
  const headFb = document.getElementsByTagName('head')[0];
  headFb.appendChild(fb);

  // Google Analytics
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MKLWJTT');
  `;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(s);

  const g4 = document.createElement('script');
  g4.type = 'text/javascript';
  g4.async = true;
  g4.src = 'https://www.googletagmanager.com/gtag/js?id=AW-623479791';
  const headg4 = document.getElementsByTagName('head')[0];
  headg4.appendChild(g4);

  const g4b = document.createElement('script');
  g4b.type = 'text/javascript';
  g4b.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'AW-623479791');

    gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted'
    });
  `;
  const headg4b = document.getElementsByTagName('head')[0];
  headg4.appendChild(g4b);

  // Add tiktok pixel
  const tiktok = document.createElement('script');
  tiktok.type = 'text/javascript';
  var tiktokPixelId = 'CPGT1URC77U3S3PJHMJG';
  const domainExtention = window.location.hostname.split('.').pop();
  if (domainExtention === 'es') {
    tiktokPixelId = 'CPU2BOJC77UA4KP62DSG';
  }
  tiktok.innerHTML = `
    (function (w, d, t) {
      w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};

      ttq.load('${tiktokPixelId}');
      ttq.page();
    })(window, document, 'ttq');
  `;
  const headTiktok = document.getElementsByTagName('head')[0];
  headTiktok.appendChild(tiktok);



  // https://support.google.com/analytics/answer/11147304?hl=pt-br#zippy=%2Cglobal-site-tag-websites

  // KOMMO CHAT
  // window['crmPluginConfig'] = {
  //   hidden: false,
  // };
  // var domain = window.location.hostname;
  // const {languageCode, url} = getLanguageFromUrl(domain, window.location.pathname);
  // let kommoID;
  // if (languageCode === 'pt') {
  //   kommoID = '1671190655';
  // } else if (languageCode === 'es') {
  //   kommoID = '1671190655'; // TODO change to spanish Pipeline ID
  // }
  // const chat = document.createElement('script');
  // chat.type = 'text/javascript';
  // chat.innerHTML = `
  //   (function(a,m,o,c,r,m){a[m]={
  //     id:"384922",hash:"c323166cad150b1c315d234e1e94f2bd956eade04c97038d3075e698c4f39c7a",
  //     locale:"${languageCode}",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};
  //     var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';
  //     s.src='https://gso.kommo.com/js/button.js?${kommoID}';
  //     d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'))
  // `;
  // const chatLk = document.getElementsByTagName('head')[0];
  // if (kommoID) {
  //   chatLk.appendChild(chat);
  // }
}

setTimeout(() => {
  const maps = document.createElement('script');
  window['mapsLoaded'] = function (a) {
    // keep dummy callback
    // console.log('MAPS LOADED', a);
  };
  maps.type = 'text/javascript';
  maps.src =
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDbswj8Q5cYfAAP3B8a6YyVIQlfeG_piI&libraries=places&language=en&callback=mapsLoaded';
  const mapsHead = document.getElementsByTagName('head')[0];
  mapsHead.appendChild(maps);
}, 1000);

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});
