
import {Subject, tap} from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from './analytics.service';
import { StoresService } from './stores.service';
import { format, parse } from 'date-fns';
import { IntlService } from './intl.service';
import { FormBuilder, UntypedFormArray, Validators } from '@angular/forms';

@Injectable()
export class MerchantsService {
  public activeMerchant = new BehaviorSubject(null);

  public doSetupFirstBooking = new BehaviorSubject(null);

  public openProductTourMenu = new Subject();

  public stickyMarketingNotification = new BehaviorSubject(null);

  constructor(private api: ApiGatewayService, private analyticsService: AnalyticsService, private storesService: StoresService, private intlService: IntlService, private formBuilder: FormBuilder) {}

  getMerchant() {
    return this.api.get(`merchants/`).pipe(
      tap((res) => {
        this.updateMerchantObservable(res);
      })
    );
  }

  getMerchantFromMemoryOrApi() {
    if (this.activeMerchant.value) {
      return this.activeMerchant;
    } else {
      return this.getMerchant();
    }
  }

  updateMerchantObservable(data) {
    if (data.country) {
      this.intlService.setCurrentCountryByName(data.country);
    }
    this.activeMerchant.next(data);
    if (data.stores) {
      const sorted = data.stores.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      this.storesService.myStores.next(sorted);
    }
    this.analyticsService.setUserId(data);
  }

  refreshMerchantSubscription() {
    return this.api.get(`merchants/subscription/refresh`).pipe(tap((res) => this.updateMerchantObservable(res)));
  }

  getMerchantsTypes() {
    return this.api.get(`merchants/types`);
  }

  getMerchantLastPaiedAt() {
    return this.api.get(`merchants/lastPaiedAt`);
  }

  createMerchant(payload) {
    return this.api.post(`merchants`, payload).pipe(tap((res) => this.updateMerchantObservable(res)));
  }

  setupMerchant(id, payload) {
    return this.api.put(`merchants/${id}/setup`, payload).pipe(tap((res) => this.updateMerchantObservable(res)));
  }

  updateMerchant(id, payload) {
    return this.api.put(`merchants/${id}`, payload).pipe(tap((res) => this.updateMerchantObservable(res)));
  }

  getClients(type = null, storeId) {
    if (type) {
      return this.api.get(`clients/?type=${type}&storeId=${storeId}`);
    } else {
      return this.api.get(`clients/?storeId=${storeId}`);
    }
  }

  searchClients(merchantId, payload) {
    return this.api.get(`merchants/${merchantId}/search/clients/?` + this.api.serializeParams(payload));
  }

  createClient(payload) {
    return this.api.post(`clients/`, payload);
  }

  createClientInBlacklist(payload) {
    return this.api.post(`clients/blacklist`, payload);
  }

  addToBlacklist(id) {
    return this.api.put(`clients/${id}/blacklist/add`);
  }

  removeFromBlacklist(id) {
    return this.api.put(`clients/${id}/blacklist/remove`);
  }

  updateClient(id, payload) {
    return this.api.put(`clients/${id}`, payload);
  }

  unsubscribeClientAllLists(id) {
    return this.api.put(`clients/${id}/unsubscribe`);
  }

  unsubscribeClient(id, code) {
    return this.api.post(`clients/${id}/unsubscribe/${code}`);
  }

  deleteClient(id) {
    return this.api.delete(`clients/${id}`);
  }

  getClientBookings(id) {
    return this.api.get(`clients/${id}/bookings`);
  }

  getClientLoyaltyStats(id) {
    return this.api.get(`clients/${id}/loyalty`);
  }

  getClientCustomFields(id) {
    return this.api.get(`clients/${id}/customfields`);
  }

  updateClientCustomFields(id, customFields) {
    return this.api.put(`clients/${id}/customfields`, { customFields });
  }

  validateSlug(slug) {
    return this.api.post('merchants/slug/check/available', { slug });
  }

  getInvoices(merchantId, page, perPage) {
    return this.api.getWithPagination(`merchants/${merchantId}/invoices/?` + this.api.serializeParams({page, perPage}));
  }

  getVatDetails(merchantId) {
    return this.api.get(`merchants/${merchantId}/vat/`);
  }

  getMerchantReferredMerchants(merchantId) {
    return this.api.get(`merchants/${merchantId}/referred/`);
  }

  updateVatDetails(merchantId, vatNumber) {
    return this.api.post(`merchants/${merchantId}/vat/`, { vatNumber });
  }

  getMerchantSetupIntent() {
    return this.api.get(`merchants/subscription/setupintent`);
  }

  getMerchantCustomFields(slug) {
    return this.api.get(`merchant-custom-fields/slug/${slug}`);
  }

  saveMerchantCustomFields(slug, payload) {
    return this.api.post(`merchant-custom-fields/slug/${slug}`, payload);
  }

  getMerchantNpsScore(slug) {
    return this.api.get(`merchant-nps/slug/${slug}`);
  }

  saveMerchantNpsScore(slug, payload) {
    return this.api.post(`merchant-nps/slug/${slug}`, payload);
  }


  convertWorkplanToObject(formArray) {
    const toReturn = {
      'mon': null,
      'tue': null,
      'wed': null,
      'thu': null,
      'fri': null,
      'sat': null,
      'sun': null
    };

    if (!formArray) {
      return toReturn;
    }

    formArray.forEach(day => {
      const schedule = [];
      day.schedule.forEach(slot => {
        schedule.push({
          'start': slot.start ? format(parse(slot.start, this.intlService.getTimeFormat(), new Date()), 'HH:mm') : null,
          'end':  slot.end ? format(parse(slot.end, this.intlService.getTimeFormat(), new Date()), 'HH:mm') : null,
        })
      });
      toReturn[day.day] = schedule;
    });

    return toReturn;

  }

  checkMarketingModals() {
    return this.api.get(`merchants/modaltoshow`).pipe(tap((res) => {
      if (res.show) {
        this.stickyMarketingNotification.next(res?.campaign);
      } else {
        this.stickyMarketingNotification.next(null);
      }
    }));
  }

  convertWorkplanToForm(obj) {
    const form: UntypedFormArray = this.formBuilder.array([]);

    Object.keys(obj).forEach((day, i) => {
      if (day !== 'id') {

        const daySchedule: UntypedFormArray = this.formBuilder.array([]);
        if (obj[day]) {
          obj[day].forEach(slot => {
            daySchedule.push(
              this.formBuilder.group({
                'start': [slot?.start && format(parse(slot.start, 'HH:mm', new Date()), this.intlService.getTimeFormat()) || null, [Validators.required]],
                'end': [slot?.end && format(parse(slot.end, 'HH:mm', new Date()), this.intlService.getTimeFormat()) || null, [Validators.required]]
              })
            );
          });
        } else {
          daySchedule.push(
            this.formBuilder.group({
              'start': [null, [Validators.required]],
              'end': [null, [Validators.required]]
            })
          );
        }

        const newGroup = this.formBuilder.group({
          'day': [day],
          'schedule': daySchedule
        });

        if (obj[day] && obj[day][0].start && obj[day][0].end) {
          newGroup.enable();
        } else {
          newGroup.disable();
        }
        form.push(newGroup);
      }
    });

    return form;

  }
}
